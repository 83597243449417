<template>
  <div>
    <b-breadcrumb>
      <b-breadcrumb-item>
        <b-icon icon="house-fill" scale="1.25" shift-v="1.25" aria-hidden="true"></b-icon>
      </b-breadcrumb-item>
      <b-breadcrumb-item active>Baby Drum</b-breadcrumb-item>
    </b-breadcrumb>
    <br>
    <b-card title="Baby Drum">
        <b-col>
          <b-row class="show-on-mobile">
            <b-col cols="6" style="margin-bottom:15px;">
                <b-form-group
                label="Sort By"
                label-for="sort-by-select"
                label-cols-md="0"
                label-align-sm="left"
                label-size="md"
                class="mb-0"
                >
                <b-input-group size="sm">
                    <b-form-select
                    id="sort-by-select"
                    v-model="sortBy"
                    :options="sortOptions"
                    class="w-75"
                    >
                    <template #first>
                        <option value="">-- none --</option>
                    </template>
                    </b-form-select>

                    <b-form-select
                    v-model="sortDesc"
                    :disabled="!sortBy"
                    :options="directionOptions"
                    size="sm"
                    class="w-25"
                    >
                    </b-form-select>
                </b-input-group>
                </b-form-group>
            </b-col>
          </b-row>
        </b-col>

        <b-col cols="12">
          <b-row style="margin-bottom:15px;">
            <b-col cols="8">
            </b-col>
              <b-col cols="4">
                  <div>
                  <b-row>
                      <span class="spin" v-if="showSpinner"></span>
                  </b-row>
                  <b-form-input
                      v-model="search"
                      @input="debounceSearch"
                      type="search"
                      placeholder="Type to Search"
                      style="float: right;"
                  />
                  </div>
              </b-col>
          </b-row>
          <b-row style="margin-bottom:10px" class="show-on-mobile">
            <b-col>
                <b-form-checkbox
                v-model="stackedStatus"
                value="md"
                unchecked-value= false
                >
                Stacked Table
                </b-form-checkbox>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <div style="overflow-x: visible;">
                <b-table
                  hover
                  outlined
                  head-variant="dark"
                  :filter="search"
                  :per-page="perPage"
                  :current-page="currentPage"
                  :items="babyDrums"
                  :fields="table_fields"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :stacked= stackedStatus
                  small
                  responsive="sm">
                  <template v-slot:cell(actions)="row">
                    <b-dropdown variant="success" size="sm" text="Menu" no-caret>
                      <template #button-content>
                        <feather-icon size="1x" icon="MenuIcon" />
                      </template>
                      <b-dropdown-item >
                        <b-button variant="success" size="sm" @click="detail(row.item)" style="margin-right:10px"><feather-icon size="1x" icon="EyeIcon" /></b-button>
                      </b-dropdown-item>
                    </b-dropdown>
                  </template>
                </b-table>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="4" class="pt-1">
              <b-form-group
                label="Data Per Page"
                label-for="per-page-select"
                label-cols-md="0"
                label-align-sm="left"
                label-size="md"
                class="mb-0"
              >
                <b-form-select
                    id="per-page-select"
                    v-model="perPage"
                    :options="pageOptions"
                    size="sm"
                ></b-form-select>
              </b-form-group>
            </b-col>
            <b-col class="pt-1">
                <b-pagination
                    v-model="currentPage"
                    :total-rows="babyDrums.length"
                    :per-page="perPage"
                    first-number
                    last-number
                    class="float-right"
                    aria-controls="user-table"
                ></b-pagination>
            </b-col>
          </b-row>
        </b-col>
    </b-card>
    <!-- Modal for showing Details-->
            <b-modal v-model="showDetailModal" title="Baby Drum Detail" size="md" ok-only >
              <b-row>
                <b-col cols="6">
                  Type :
                </b-col>
                <b-col cols="6">
                  {{detailModal.babyDrumType}}
                </b-col>
                <b-col cols="6">
                  Size :
                </b-col>
                <b-col cols="6">
                  {{detailModal.babyDrumSize}}
                </b-col>
                <b-col cols="6">
                  Status :
                </b-col>
                <b-col cols="6">
                  {{detailModal.babyDrumStatus}}
                </b-col>
                <b-col cols="6">
                  Created at :
                </b-col>
                <b-col cols="6">
                  {{detailModal.createdAt}}
                </b-col>
                <b-col cols="6">
                  Updated at :
                </b-col>
                <b-col cols="6">
                  {{detailModal.updatedAt}}
                </b-col>
              </b-row>
              <template #modal-footer>
                <div class="w-100">    
                </div>
              </template>
            </b-modal>
  </div>
</template>

<script>


import { dateFormat, userAccess, userAccessManufacture, viewAccess } from '@/utils/utils.js';
import {mapActions} from 'vuex';
import vSelect from 'vue-select';

export default {
  components: {
    vSelect
  },
  data() {
    return {
      stackedStatus:"md",
      showSpinner: false,
      //sort direction list
      directions: [
          { key: false, label: 'Asc', sortable: true },
          { key: true, label: 'Desc', sortable: true },
      ],
      search: "",
      pageOptions: [5, 10, 20, 100],
      sortBy: '',
      sortDesc: false,
      perPage: 5,
      currentPage: 1,
      showDetailModal: false,
      detailModal: ""

      
    }
  },
  methods: {
    ...mapActions({
    }),
    // add spinner while loading on searching data process
    debounceSearch(event) {
        this.showSpinner = true
        clearTimeout(this.debounce)
        this.debounce = setTimeout(() => {
            this.showSpinner = false
            this.search = event.target.value
        }, 600)
    },
    
    detail(item){
      this.detailModal = item,
      this.showDetailModal=true;
    },
    
  },
  computed: {
    permission() {
      return userAccess('baby drum');
    },
    babyDrums(){
      var listData = this.$store.getters["item/getBabyDrum"]

      listData.forEach((x) => {
        if(x.babyDrumType == "W"){
          x.babyDrumType = "Wooden"
        }
        else if(x.babyDrumType == "S"){
          x.babyDrumType = "Steel"
        }

        if(x.babyDrumStatus == "A"){
          x.babyDrumStatus = "Active"
        }
        else if(x.babyDrumStatus == "I"){
          x.babyDrumStatus = "Inactive"
        }
        x.createdAt = dateFormat(x.createdAt)
        x.updatedAt = dateFormat(x.updatedAt)
      })
      return listData

    },
    rows() {
      return this.babyDrums.length;
    },
    table_fields(){
      return [
        {
          key: "babyDrumType",
          label: "Type",
          filterable: true,
          sortable: true,
        //   stickyColumn: true,
        },
        {
          key: "babyDrumSize",
          label: "Size",
          filterable: true,
          sortable: true,
        //   stickyColumn: true,
        },
        {
          key: "babyDrumStatus",
          label: "Status",
          filterable: true,
          sortable: true,
        //   stickyColumn: true,
        },
        {
          key: "createdAt",
          label: "Created At",
          filterable: true,
          sortable: true,
        //   stickyColumn: true,
        },'actions']
    },

    // Create an options list from our fields
    sortOptions() {
      return this.table_fields
        .filter(f => f.sortable)
        .map(f => {
        return { text: f.label, value: f.key }
        })
    },
    // Create an direction list that can be saved in session
    directionOptions() {
    return this.directions
        .filter(f => f.sortable)
        .map(f => {
        return { text: f.label, value: f.key }
        })
    },
  },
  created() {},
  mounted(){
    this.$store.dispatch("item/getBabyDrum");
    // this.$store.dispatch("bom/getBOM")

    // Saving Menu Setting on localstorage session so it still same even after reloading the page
    if (this.$session.has("perPageBomManufacture")) {
      this.perPage = this.$session.get("perPageBomManufacture")
    }
    if (this.$session.has("sortByBomManufacture")) {
      this.sortBy = this.$session.get("sortByBomManufacture")
    }
    if (this.$session.has("sortDescBomManufacture")) {
      this.sortDesc = this.$session.get("sortDescBomManufacture")
    }
    // if (this.$session.has("stackedStatusBomManufacture")) {
    //   this.stackedStatus = this.$session.get("stackedStatusBomManufacture")
    // }
  },

  watch: {
    // Taking the Menu Setting from localstorage session so the setting will be the same as before
    perPage(perPageNew) {
      this.$session.set("perPageBomManufacture", perPageNew)
    },
    sortBy(sortByNew) {
      this.$session.set("sortByBomManufacture", sortByNew)
    },
    sortDesc(sortDescNew) {
      this.$session.set("sortDescBomManufacture", sortDescNew)
    },
    // stackedStatus(stackedStatusNew) {
    //   this.$session.set("stackedStatusBomManufacture", stackedStatusNew)
    // }
  },
}
</script>

<style>
  @media (min-width: 761px) {
    .show-on-mobile { display: none !important; }
  }
  @keyframes spinner {
    0% {
    transform: translate3d(-50%, -50%, 0) rotate(0deg);
    }
    100% {
    transform: translate3d(-50%, -50%, 0) rotate(360deg);
    }
  }
  .spin::before {
    animation: 1.5s linear infinite spinner;
    animation-play-state: inherit;
    border: solid 5px #cfd0d1;
    border-bottom-color: #0077B3;
    border-radius: 50%;
    content: "";
    height: 20px;
    width: 20px;
    position: absolute;
    margin-top: 20px;
    transform: translate3d(-50%, -50%, 0);
    will-change: transform;
  }
</style>